<template>
  <div class="my">
    <div class="my-aside">
      <div class="top" >
        <div class="flex">
          <div class="left"  >
            <img :src="userInfo.avatar" alt="" class="img" v-if="userInfo" />
             <upload-img @input="getimg" class="getimg"></upload-img>
              <img src="@img/camera.png" alt=""  class="camera"/>
          </div>
          <div class="right">
            <div class="name">           
              <input type="text" v-model="nickname" class="editarea" v-show="canedit" ref="editarea" @blur="changenickname(nickname)">
              <div v-show="!canedit" >{{userInfo.nickname}} <img src="@img/edit.png"  class="edit" @click="editnickname()"/></div>
             
            </div>
            <div class="phone">{{ userInfo.mobile }}</div>
            <div class="company">{{ userInfo.company_name }}</div>
           
          </div>
        </div>
        <div class="renzhen">
          <img src="@img/renzheng.png" alt="" v-if="userInfo.is_cert_text!='待认证'"/>
          <span v-else style="width:10px"></span>
          <span>{{userInfo.is_cert_text}}</span>
        </div>
      </div>
      <div class="tabs">
        <div class="activebar" :style="barstyle"></div>
        <div class="tabitem" v-for="(item,i) in menu" :key="i" @click="togglemenu(i)" :class="{'on':i==menuon?'on':''}" @mouseover="hoverindex=i">
          <span class="leftnav"><img :src="(i==menuon||i==hoverindex)?item.iconon:item.icon" alt="" class="icon">{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="my-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import uploadImg from "@c/common/uploadhead.vue";
import { mapState,mapMutations } from "vuex";
export default {

  created() {
    if (this.$route.path.indexOf("/my") >= 0 && !this.isLogin) {
      this.$router.push({ path: "/" });
    }
  },
  data() {
    return {
      menuon: 0,
      hoverindex:0,
      menu: [
        {
          name: "我发布的需求",
          icon: require("@img/myneed.png"),
          iconon: require("@img/myneed-on.png"),
          path: "/my/needs",
        },
        {
          name: "我的收藏",
          icon: require("@img/wdsc.png"),
          iconon: require("@img/wdsc-on.png"),
          path: "/my/store",
        },
        {
          name: "意见反馈",
          icon: require("@img/yjfk.png"),
          iconon: require("@img/yjfk-on.png"),
          path: "/my/feedback",
        },
       
      ],
      nickname:"",
      id: this.$route.query.id,
       canedit:false
    };
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState(["isLogin", "userInfo"]),
    barstyle(){
      return "transform:translateY("+this.menuon*80+"px)";
    }
  },
  components: { uploadImg },
  mounted() {},
  // 方法集合
  methods: {
      ...mapMutations([
      "setLogin",
      "setUserInfo",
    
    ]),
     changenickname(val){
    
       if(val){
          if(val==this.userInfo.nickname){
            this.$message.error({ message: "请填写新的昵称" });
             this.canedit=false;
          }else{
            this.changeinfo({
                nickname:val
              },(msg)=>{
                this.$message.success({ message:msg });
                 this.canedit=false;
              })
          }
        
       }else{
         this.$message.error({ message: "昵称不能为空" });
          this.canedit=false;
       }
      
    },
    editnickname(){
      this.canedit=true;
      this.nickname=this.userInfo.nickname;
      this.$nextTick(()=>{
          this.$refs.editarea.focus();
      })
    },
    async changeinfo(obj,callback){
       
        var res = await this.$http.changeinfo(obj);
      
        if(res.code==1){
          var info=Object.assign({},this.userInfo,obj)
          // console.log(info)
          this.setUserInfo(info);
          if(callback){
            callback(res.msg);
          }
        }else{
           this.$message.error({ message: res.msg });
        }
       
    },
     getimg(url) {
    
      if(url){
        this.changeinfo({
          avatar:url
        })
      }
    },
    openpage(path,id){
         this.$router.push({
          path,
          query: {
            id,
          },
        })
    },
    togglemenu(i){
      if(i==this.menuon){
        return
      }else{
        console.log(this.menuon,i)
        this.menuon=i;
        if(i==0){
          this.openpage("/my/needs")
        }else if(i==1){
           this.openpage("/my/store")
        }else{
           this.openpage("/my/feedback")
        }
      }
        
    }
  },
  watch: {
    // 有子路由才能监听
    $route(to, from) {
      this.id =  to.query.id;
  
       if(to.params.hasOwnProperty("num")){
        this.menuon=to.params.num;
      }
    
    },
  },
};
</script>
<style scoped lang="scss">
.editarea{
 width: 100%;
  outline:  none!important;
  font-size: 20px;
  font-weight: bold;
}
.my{
    width: 100%;
    height: 960px;
    margin-bottom: 20px;
    .my-aside{
        width: 300px;
        height: 100%;
        background: #D8E9FF;
        float: left;
        .top{
            padding: 30px;
            margin-bottom: 0px;
            .left{
               
                width: 60px;
                height: 60px;
                border-radius: 100%;
                overflow: hidden;
                position: relative;
                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .camera{
                   position: absolute;
                  bottom: 0;
                  left: 0;
                    width: 100%;
                    z-index: 0;
                }
                .getimg{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0;
                    overflow: hidden;
                }
            }
            .right{
                flex: 1;
                margin-left: 20px;
                font-size: 14px;
                position: relative;
                .edit{
                  /*  position: absolute;
                  right: -10px;
                  top: 4px;  */
                 transform: translateY(2px);
                 margin-left: 2px;
                  cursor: pointer;
                  width: 21px;
                  height: 21px;
                }
                .name{
                    font-size: 20px;
                    font-weight: bold;
                 
                }
                .phone{
                    
                }
            }
            .renzhen{
                margin-top: 5px;
                 font-size: 14px;
                 display: flex;
                 align-items: center;
                 img{
                     margin-right: 5px;
                 }
            }
        }
        .tabs{
            position: relative;
            cursor: pointer;
            .activebar{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 6px;
                  height: 80px;
                  background-color: #1879F5;
                  z-index: 1;
                  transition: transform .3s cubic-bezier(.645,.045,.355,1);
              
            }
            .tabitem{
                padding: 0 20px;
                line-height: 40px;
                width: 100%;
                height: 80px;
                font-size: 20px;
                display: flex;
                align-items: center;
                &:hover{
                   color: #1879F5;
                }
                .leftnav{
                    display: flex;
                    align-items: center;
                    .icon{
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
                &.on{
                    background: #fff;
                    color: #1879F5;
                }
            }
        }
    }
    .my-main{
        height: 960px;
        overflow-y: auto;  
        background: #fff;
    }
}
</style>
